* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;



[data-amplify-authenticator] {
  --amplify-components-authenticator-router-background-color: var(--color-neutral-900);
  --amplify-components-authenticator-router-border-color: var(--color-neutral-700);
  --amplify-components-field-label-color: var(--color-gray-100);
  --amplify-colors-background-primary: var(--amplify-colors-neutral-90);
  --amplify-colors-background-secondary: var(--amplify-colors-neutral-100);
  --amplify-colors-primary-10: var(--nextui-foreground-500);
  --amplify-colors-primary-80: var(--nextui-foreground);
  --amplify-colors-primary-90: var(--nextui-foreground);
  --amplify-colors-primary-100: var(--nextui-primary);
  --amplify-colors-font-interactive: var(--nextui-primary);
  --amplify-components-tabs-item-font-weight: normal;
  --amplify-components-tabs-item-active-color: var(--color-primary-500);
  --amplify-components-tabs-item-focus-color: var(--nextui-primary);
  --amplify-components-tabs-item-hover-color: var(--color-primary-500); 
  --amplify-components-tabs-item-active-border-color: var(--color-primary-500);
  --amplify-internal-button-background-color: var(--color-primary-500);
  --amplify-components-button-link-color: var(--amplify-colors-gray-90);
  --amplify-components-button-link-hover-color: var(--color-primary-500);
  --amplify-components-fieldcontrol-border-radius: 0;

  --amplify-components-authenticator-state-inactive-background-color: var(--amplify-colors-gray-100);
  --amplify-components-tabs-item-color: var(--amplify-colors-gray-100);

  --amplify-components-passwordfield-button-color: var(--amplify-colors-gray-90);
  --amplify-components-passwordfield-button-hover-color: var(--color-primary-500);
  --amplify-components-passwordfield-button-hover-border-color: var(--color-primary-500);
  --amplify-components-passwordfield-button-active-color: var(--amplify-colors-gray-90);
  --amplify-components-fieldcontrol-line-height: 30px;
}

.amplify-button--primary {
  --amplify-internal-button-background-color: var(--color-primary-500);
  --amplify-components-button-font-weight: normal;
  --amplify-internal-button-color: var(--color-neutral-900);

  transition-property: transform, color, background, background-color,
    border-color, text-decoration-color, fill, stroke;
  transition-timing-function: ease;
  transition-duration: 250ms;
  font-size: var(--nextui-font-size-small);
  line-height: var(--nextui-line-height-small);
  padding-left: var(--nextui-spacing-unit-4);
  padding-right: var(--nextui-spacing-unit-4);
  background-color: var(--amplify-internal-button-background-color);
  border-radius: var(--nextui-radius-medium);
}
