@font-face {
  font-family: 'Work Sans';
  src: url('../public/WorkSans-BlackItalic.woff2') format('woff2'),
      url('../public/WorkSans-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('../public/WorkSans-ExtraBoldItalic.woff2') format('woff2'),
      url('../public/WorkSans-ExtraBoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('../public/WorkSans-BoldItalic.woff2') format('woff2'),
      url('../public/WorkSans-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('../public/WorkSans-Thin.woff2') format('woff2'),
      url('../public/WorkSans-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('../public/WorkSans-MediumItalic.woff2') format('woff2'),
      url('../public/WorkSans-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('../public/WorkSans-ThinItalic.woff2') format('woff2'),
      url('../public/WorkSans-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('../public/WorkSans-Italic.woff2') format('woff2'),
      url('../public/WorkSans-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('../public/WorkSans-LightItalic.woff2') format('woff2'),
      url('../public/WorkSans-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('../public/WorkSans-Regular.woff2') format('woff2'),
      url('../public/WorkSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('../public/WorkSans-Bold.woff2') format('woff2'),
      url('../public/WorkSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('../public/WorkSans-ExtraBold.woff2') format('woff2'),
      url('../public/WorkSans-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('../public/WorkSans-Medium.woff2') format('woff2'),
      url('../public/WorkSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('../public/WorkSans-Black.woff2') format('woff2'),
      url('../public/WorkSans-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('../public/WorkSans-SemiBold.woff2') format('woff2'),
      url('../public/WorkSans-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('../public/WorkSans-SemiBoldItalic.woff2') format('woff2'),
      url('../public/WorkSans-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('../public/WorkSans-ExtraLight.woff2') format('woff2'),
      url('../public/WorkSans-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('../public/WorkSans-Light.woff2') format('woff2'),
      url('../public/WorkSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('../public/WorkSans-ExtraLightItalic.woff2') format('woff2'),
      url('../public/WorkSans-ExtraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

body {
  margin: 0;
  font-family: 'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}